import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import ProductsPageTemplate from "../templates/ProductsPageTemplate"

const ProductsPage = ({ data }) => {
  const fm = data.directus.productsPage
  const { products: actualItems } = data.directus

  return (
    <>
      <MyHelmet title={fm.title} description={fm.subHeading} />
      <ProductsPageTemplate
        heading={fm.heading}
        subheading={fm.subHeading}
        posts={actualItems}
      />
    </>
  )
}

export default ProductsPage

export const productsPageQuery = graphql`
  query ProductsPage {
    directus {
      productsPage {
        heading
        subHeading
      }
      products(
        filter: { _and: [{ hidden: { _eq: false } }] }
        sort: ["-date"]
      ) {
        id
        title
        date
        pinned
        slug
        subTitle
        featuredImage {
          id
        }
        body
      }
    }
  }
`
